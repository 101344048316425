import update from 'immutability-helper';

export default function ToolbarButtons({
  allowFileUploads = false,
  allowHTML = false,
  exclude = [],
  textOnly = false
} = {}) {
  const allToolbarButtons = [
    // eslint-disable-next-line max-len
    ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize'],
    ['textColor', 'backgroundColor', 'emoticons', 'inlineStyle'],
    ['paragraphStyle', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent'],
    ['quote', 'insertHR', 'insertLink', 'insertImage', 'insertVideo', 'insertFile', 'insertTable',
      'undo', 'redo', 'clearFormatting', 'selectAll', 'html']
  ];

  if (textOnly) {
    return [['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
      'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'undo', 'redo',
      'clearFormatting', 'selectAll'
    ]];
  }

  if (!allowHTML) {
    exclude.push('html');
  }

  if (!allowFileUploads) {
    exclude.push(...['insertImage', 'insertVideo', 'insertFile']);
  }

  if (!exclude) {
    return allToolbarButtons;
  }

  return allToolbarButtons.reduce((buttonGroups, buttonGroup) => {
    const filteredGroup = buttonGroup.filter(el => !exclude.includes(el));
    return update(buttonGroups, { $push: [filteredGroup] });
  }, []);
}
