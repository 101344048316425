/* eslint-disable no-console */

export const imageErrorEvent = (error) => {
  if (error.code === 1) console.log('Bad link.');
  else if (error.code === 2) console.log('No link in upload response.');
  else if (error.code === 3) console.log('Error during image upload.');
  else if (error.code === 4) console.log('Parsing response failed.');
  else if (error.code === 5) console.log('Image too large.');
  else if (error.code === 6) console.log('Invalid image type.');
  else if (error.code === 7) console.log('Image can be uploaded only to same domain in IE 8 and IE 9.');
};

export const imageManagerErrorEvent = (error) => {
  if (error.code === 10) console.log('Bad link. One of the returned image links cannot be loaded.');
  else if (error.code === 11) console.log('Error during request.');
  else if (error.code === 12) console.log('Missing imagesLoadURL option.');
  else if (error.code === 13) console.log('Parsing response failed.');
};

export const fileErrorEvent = (error) => {
  if (error.code === 1) console.log('Bad link.');
  else if (error.code === 2) console.log('No link in upload response.');
  else if (error.code === 3) console.log('Error during file upload.');
  else if (error.code === 4) console.log('Parsing response failed.');
  else if (error.code === 5) console.log('File too large.');
  else if (error.code === 6) console.log('Invalid file type.');
  else if (error.code === 7) console.log('File can be uploaded only to same domain in IE 8 and IE 9.');
};

export function livePreviewEvent() {
  $('#froala-preview').html(this.html.get());
}
