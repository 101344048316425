import update from 'immutability-helper';
import toolbarButtons from './ToolbarButtons';
import { fileErrorEvent, imageErrorEvent, imageManagerErrorEvent, livePreviewEvent } from './FroalaEvents';

const codeMirror = require('codemirror');

const DEFAULT_HEIGHT = 390;
const DEFAULT_WIDTH = 840;

const defaultConfig = ({
  allowFileUploads,
  allowHTML,
  events,
  textOnly,
  ...rest
}) => ({
  attribution: false,
  charCounterCount: true,
  codeMirror,
  codeMirrorOptions: {
    autoCloseTags: true,
    lineNumbers: true,
    lineWrapping: true,
    mode: 'htmlmixed',
  },
  colorsBackground: [
    '#000000', '#555555', '#777770', '#cccccc',
    '#eeeeee', '#ffffff', '#EA363B', '#F88222',
    '#FCC521', '#269A40', '#26B9CA', '#0072BC',
    '#8445D3', '#F37AB1', '#D2232A', '#F69C05',
    '#2A8806', '#247F99', '#522E91', 'REMOVE'
  ],
  colorsStep: 5,
  colorsText: [
    '#000000', '#555555', '#777770', '#cccccc',
    '#eeeeee', '#ffffff', '#EA363B', '#F88222',
    '#FCC521', '#269A40', '#26B9CA', '#0072BC',
    '#8445D3', '#F37AB1', '#D2232A', '#F69C05',
    '#2A8806', '#247F99', '#522E91', 'REMOVE'
  ],
  events: events,
  height: DEFAULT_HEIGHT,
  inlineStyles: {
    'Large Bold Blue': 'font-size: 30px; font-weight: bold; color: blue;',
    'Small Red': 'font-size: 10px; color: red',
    'TCI Purple default': 'font-size: 12px; color: #522e91;'
  },
  key: '5OA4gB3G3H3B2A5A4A3F-11SLJCKHXOSLMc1YGSGb1ZXHSe1CgB5A4D4A3E3C2A17A19A6B5==',
  listAdvancedTypes: false,
  pasteDeniedTags: ['img'],
  pluginsEnabled: [
    'align', 'colors', 'charCounter', 'codeBeautifier', 'codeView', 'emoticons',
    'imageManager', 'fullscreen', 'fontFamily', 'fontSize', 'inlineStyle', 'link',
    'image', 'video', 'file', 'table', 'paragraphStyle', 'paragraphFormat', 'quote',
    'lists',
  ],
  toolbarButtons: toolbarButtons({
    allowFileUploads,
    allowHTML,
    textOnly
  }),
  width: DEFAULT_WIDTH,
  ...rest
});

const FroalaConfig = ({
  allowFileUploads,
  allowHTML,
  baseUrl,
  customConfig = {},
  events = {},
  fileUploadParam,
  imageUploadParam,
  livePreview = false,
  textOnly = false,
  ...rest
}) => {
  let config = defaultConfig({
    allowFileUploads,
    allowHTML,
    events,
    textOnly,
    ...rest
  });

  if (allowFileUploads) {
    if (baseUrl) {
      const mediaUploadURL = baseUrl.concat('/add_media');
      const getMediaURL = baseUrl.concat('/get_media');
      const deleteMediaURL = baseUrl.concat('/delete_media');
      config.imageUploadURL = mediaUploadURL;
      config.fileUploadURL = mediaUploadURL;
      config.imageManagerLoadURL = getMediaURL;
      config.fileManagerLoadURL = getMediaURL;
      config.imageManagerDeleteURL = deleteMediaURL;
      config.fileManagerDeleteURL = deleteMediaURL;
    }

    const authToken = $('meta[name=csrf-token]').attr('content');

    /* File/Image Upload Config */
    config.imageUploadParam = imageUploadParam;
    if (fileUploadParam) config.fileUploadParam = fileUploadParam;
    config.imageUploadParams = {
      authenticity_token: authToken,
      'froala[action]': 'upload',
      'froala[type]': 'image',
    };
    config.fileUploadParams = {
      authenticity_token: authToken,
      'froala[action]': 'upload',
      'froala[type]': 'file',
    };
    config.imageUploadMethod = 'POST';
    config.fileUploadMethod = 'POST';
    config.imageMaxSize = 5 * 1024 * 1024;
    config.fileMaxSize = 5 * 1024 * 1024;
    config.imageAllowedTypes = ['jpeg', 'jpg', 'png', 'gif'];
    config.fileAllowedTypes = ['application/pdf'];

    /* File/Image Manager Config */
    config.imageManagerPageSize = 20;
    config.fileManagerPageSize = 20;
    config.imageManagerScrollOffset = 10;
    config.fileManagerScrollOffset = 10;
    config.imageManagerLoadMethod = 'GET';
    config.fileManagerLoadMethod = 'GET';
    config.imageManagerLoadParams = {
      'froala[action]': 'get',
      'froala[type]': 'image',
    };
    config.fileManagerLoadParams = {
      'froala[action]': 'get',
      'froala[type]': 'file',
    };

    /* File/Image Deletion Config */
    config.imageManagerDeleteMethod = 'POST';
    config.fileManagerDeleteMethod = 'POST';
    config.imageManagerDeleteParams = {
      authenticity_token: authToken,
      'froala[action]': 'delete',
      'froala[type]': 'image',
    };
    config.fileManagerDeleteParams = {
      authenticity_token: authToken,
      'froala[action]': 'delete',
      'froala[type]': 'file',
    };
    config.events['image.error'] = imageErrorEvent;
    config.events['imageManager.error'] = imageManagerErrorEvent;
    config.events['file.error'] = fileErrorEvent;
  }

  if (allowHTML && !config.toolbarButtons.includes('html')) {
    config.toolbarButtons[config.toolbarButtons.length - 1].push('html');
  }

  if (livePreview) {
    config.events.contentChanged = livePreviewEvent;
  }

  if (customConfig) {
    config = update(config, {
      $merge: customConfig
    });
  }

  return config;
};


export default FroalaConfig;
